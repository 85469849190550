// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ems-modal-js": () => import("./../../../src/pages/emsModal.js" /* webpackChunkName: "component---src-pages-ems-modal-js" */),
  "component---src-pages-hifu-modal-js": () => import("./../../../src/pages/hifuModal.js" /* webpackChunkName: "component---src-pages-hifu-modal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laser-modal-js": () => import("./../../../src/pages/laserModal.js" /* webpackChunkName: "component---src-pages-laser-modal-js" */),
  "component---src-pages-qanda-js": () => import("./../../../src/pages/qanda.js" /* webpackChunkName: "component---src-pages-qanda-js" */)
}

